import React from 'react';

const About = () => (
  <section className="section">
    <div className="container">
      <h1 className="title has-text-centered">About</h1>
      <p>
        I am a driven full stack software engineer who loves the challenge of
        learning a new technology. I ramp up quickly on projects and would be an
        asset to anyone's team. I currently live in the New Jersey suburbs with
        my partner and four crazy doggos. Life is good.
      </p>
    </div>
  </section>
);

export default About;
